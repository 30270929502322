import React, {useState} from 'react'
import { Tabs, Tab } from "react-bootstrap"
import India from './India'
import Malaysia from './Malaysia'
import Singapore from './Singapore'
import UnitedKingdom from './UnitedKingdom'
import Zambia from './Zambia'

const ContactTabsContainer = () => {
    
    const [key, setKey] = useState('india');

    return (
        <Tabs id="country-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="india" title="India">
        <India />
      </Tab>
      <Tab eventKey="malaysia" title="Malaysia">
        <Malaysia />
      </Tab>
      <Tab eventKey="singapore" title="Singapore">
        <Singapore />
      </Tab>
      <Tab eventKey="united-kingdom" title="United Kingdom">
        <UnitedKingdom />
      </Tab>
      <Tab eventKey="Zambia" title="Zambia">
        <Zambia />
      </Tab>
    </Tabs>
    )
}

export default ContactTabsContainer