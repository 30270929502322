import React from 'react'
import { Card } from "react-bootstrap"

const Singapore = () => {
    return (
        <div className="row mt-4">
        <div className="col-md-4">
            <Card className="cardShadow">
                <Card.Body>
                    <Card.Title>Singapore</Card.Title>
                    <Card.Text>1 North Bridge Road, High Street Centre #19-04/05, Singapore</Card.Text>
                    <Card.Text>singapore@crampete.com</Card.Text>
                    <Card.Text>+60 12-336 9057</Card.Text>
                </Card.Body>
            </Card>
        </div>
    </div>
    )
}
export default Singapore