import React from 'react'
import { Card } from "react-bootstrap"

const India = () => {
  return (
    <div className="row mt-4">
      <div className="col-md-4">
        <Card className="cardShadow">
          <Card.Body>
            <Card.Title>Chennai - T.Nagar</Card.Title>
            <Card.Text>
              #132, Habibullah Rd, Satyamurthy Nagar, T. Nagar, Chennai,
              Tamil Nadu 600017
            </Card.Text>
            <Card.Text>swaminathan@crampete.com</Card.Text>
            <Card.Text>+91 93840 58989</Card.Text>
          </Card.Body>
        </Card>
      </div>
      {/* <div className="col-md-4">
        <Card className="cardShadow">
          <Card.Body>
            <Card.Title>Erode - Bhavani</Card.Title>
            <Card.Text>2nd Floor, No 8 Weekly Market Road
              Above Kosamattam Gold Loan
              Bhavani, Erode -638301</Card.Text>
            <Card.Text>marketing@crampete.com</Card.Text>
            <Card.Text>+91 97915 70877</Card.Text>
          </Card.Body>
        </Card>
      </div> */}
      {/* <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Hanumanth Nagar</Card.Title>
                  <Card.Text>NICT Computer Education # 13/30, 50 feet Main Road,
                      Hanumanthnagar Bangalore 560050. Karnataka, India</Card.Text>
                    <Card.Text>shivakumar@nictinfo.com</Card.Text>
                    <Card.Text>+91 76763 12345</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Vijayanagar</Card.Title>
                  <Card.Text>NICT Computer Education # 39/39, 91st "E" cross, REMCO
                      layout, vijayanagar 2 nd stage Bangalore - 40. Karnataka,
                      India oppo BMTC Bus stop, metro pillar no : 331</Card.Text>
                    <Card.Text>kanakaraj@nicteducation.com</Card.Text>
                    <Card.Text>+91 73386 80777, +91 73386 80777</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Rammurthynagar</Card.Title>
                  <Card.Text>NICT Computer Education # 183, 1st Main Road 2nd floor,
                      Rammurthynagar Bangalore 560016. Karnataka, India oppo old
                      police station</Card.Text>
                    <Card.Text>bangalore@crampete.com</Card.Text>
                    <Card.Text>+91 996194324, +91 80416 79695 </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Jayanagar</Card.Title>
                  <Card.Text>
                      NICT Computer Education # 791, 9th Main Road 4th Block
                      west, Jayanagar Bangalore 560011. Karnataka, India</Card.Text>
                    <Card.Text>bangalore@crampete.com</Card.Text>
                    <Card.Text>+91 996194324, +91 80416 79695 </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bhubaneswar</Card.Title>
                  <Card.Text>Regional Collage of Managment , Chakadola Dham,
                      Chandrasekharpur, Bhubanswar- 751023, Odisha</Card.Text>
                    <Card.Text>bhubaneswar@crampete.com</Card.Text>
                    <Card.Text>+91 74370 00204</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Raipur</Card.Title>
                  <Card.Text>Anion Softech Pvt. Ltd , 1st Floor, Maulshree Vihar
                      Offizo, Maulshree Vihar, V.I.P ROAD, Raipur 492006</Card.Text>
                    <Card.Text>raipur@crampete.com</Card.Text>
                    <Card.Text>+91 78060 28620 / +91 79993 15868</Card.Text>
                </Card.Body>
              </Card>
            </div> */}
    </div>
  )
}

export default India