import React from 'react'
import { Card } from "react-bootstrap"

const UnitedKingdom = () => {
    return (
        <div className="row mt-4">
        <div className="col-md-4">
            <Card className="cardShadow">
                <Card.Body>
                    <Card.Title>London</Card.Title>
                    <Card.Text>First Floor, #14, Elm Road, Chessington, Surrey, KT9 1AW, United Kingdom</Card.Text>
                    <Card.Text>london@crampete.com</Card.Text>
                    <Card.Text>+44 20 3598 2904</Card.Text>
                </Card.Body>
            </Card>
        </div>
    </div>
    )
}
export default UnitedKingdom