import React from 'react'
import { Card } from "react-bootstrap"

const Malaysia = () => {
    return (
        <div className="row mt-4">
            <div className="col-md-4">
                <Card className="cardShadow">
                    <Card.Body>
                        <Card.Title>Selangor</Card.Title>
                        <Card.Text>Block B-04-07A, Pusat Perdagangan Puchong Prima, Jalan Prima 5/5, 47100 Puchong, Selangor</Card.Text>
                        <Card.Text>malaysia@crampete.com</Card.Text>
                        <Card.Text>+60 12-336 9057</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Malaysia